// Gutter on small devices

.container {
  position: relative;
  @include media-breakpoint-down(sm) {
    padding-left:  $spacing-sm;
    padding-right: $spacing-sm;
  }
}

.mw-1280 {
  max-width: 1280px;
}

.container-lg {
  margin-left:  $spacing-md;
  margin-right: $spacing-md;
  max-width: 1240px;
  margin: 0 auto;
}

.last-section {
  padding-bottom: 196px;
  @include media-breakpoint-down(sm) {
    padding-bottom: 144px;
  }
}

section {
  padding: 0 0 2rem 0;
  @include media-breakpoint-up(md) {
    padding: 0 0 4rem 0;
  }
}

// Nav Link Collapse

.nav-link {
  
  font-family: $sans-serif;

  &[data-toggle="collapse"]{
    > .icon{
      transform: rotateZ(-90deg);
      position: relative;
      right: .25rem;
    }
  }
}

footer .nav-link {
  font-family: inherit;
}

// Scroll-to-top button

.btn-primary.btn-primary {
    border-color: inherit;
    border: 1px solid rgba(0, 0, 0, 0.02) !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
    &:hover,
    &:active {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20) !important;
      background-color: inherit;
    }
}

// Links

a {
  font-weight: normal;
}

a h3 {
  display: inline;

  &:hover {
      color: rgb(51,51,51);
      padding-bottom: 3px;
      background: linear-gradient(to right, rgb(51,51,51) 0%, rgb(51,51,51) 100%);
      background-size: 1px 1px;
      background-position: 0 100%;
      background-repeat: repeat-x;
  }
}

.navbar, a {
  font-weight: 400;
}

.underlined {
  text-decoration: none;
  color: rgb(25,25,25);
  padding-bottom: 0px;
  background: linear-gradient(to right, $primary-lighter 0%, $primary-lighter 100%);
  background-size: 1px 8px;
  background-position: 0 76%;
  background-repeat: repeat-x;
}

.collapsible-link.collapsible-link {
  border-bottom: none;
  color: $light-blue;

  &:hover {
    color: darken($light-blue, 10%)
  }
}

.section {

  a {
    color: $light-blue;
    &:hover {
      color: $light-blue;
    }
  }

  p a, 
  li a {
    border-bottom: 1px solid #00829A;
    color: $body-color;
    &:hover {
      color: $light-blue;
    }
  }
}

// Images

a img,
.display-4 a,
.homepage h1 a:hover,
a:hover .cover-img {
    transition:     opacity 0.2s ease-in-out;
    -moz-transition:  opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition:    opacity 0.2s ease-in-out;
  opacity: 1;

  &:hover {
    opacity: 0.6;
  }
}

.mx-w-650 {
  max-width: 650px;
}

// Shadows

.shadow-md {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15) !important;
}

// Rounded

.rounded-xl {
  border-radius: 22px;
}

// background

.bg-dark-grey {
  background: #f3f3f5;
}