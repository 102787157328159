// owl-carousel.scss

.project-carousel {
  
  padding: 48px 0 96px 0;
  
  .owl-stage-outer {
    margin-right: -10px;
  }

  .owl-stage { 
  
    margin: 8px;

    .owl-item {

      .card {
        border-radius: 0;
        border: none;
        box-shadow: none;
        a {
          &:hover {
            color: inherit;
            cursor: pointer;
          }
        }
      }
      
      .card-body {
        padding-left: 0;
      }
    }
  }
}
