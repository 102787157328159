// fontface.scss


// Apercru

@font-face {
    font-family: 'Apercu';
    src: url('/fonts/apercu/apercu-light.eot');
    src: url('/fonts/apercu/apercu-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/apercu/apercu-light.woff2') format('woff2'),
         url('/fonts/apercu/apercu-light.woff') format('woff'),
         url('/fonts/apercu/apercu-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('/fonts/apercu/apercu-regular.eot');
    src: url('/fonts/apercu/apercu-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/apercu/apercu-regular.woff2') format('woff2'),
         url('/fonts/apercu/apercu-regular.woff') format('woff'),
         url('/fonts/apercu/apercu-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('/fonts/apercu/apercu-bold.eot');
    src: url('/fonts/apercu/apercu-bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/apercu/apercu-bold.woff2') format('woff2'),
         url('/fonts/apercu/apercu-bold.woff') format('woff'),
         url('/fonts/apercu/apercu-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

// Noto serif

@import url('https://fonts.googleapis.com/css?family=Noto+Serif+KR:400,500,600,700&display=swap');

// Fallback fonts
//

// Noto Sans
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700&display=swap');
