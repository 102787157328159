// colours.scss


// white

$white:       #fff;

// grey

$dark-grey:   #111;
$gray-900:    #343734;
$light-grey:  #949494;
$gray-100:    #ced4da;
$cover-gray:  #f8f8fa;

// blacks

$overlay:     rgba(0, 0, 0, 0.67);
$off-black:   rgba(0, 0, 0, 0.8);

// blue

$light-blue:  #00829A;

// green

$green-ram:  #105540;

.green.green.green {
  color: $green-ram;
}