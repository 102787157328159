// fontface.scss


// Apercru

@font-face {
    font-family: 'Apercu';
    src: url('../scss/fonts/apercu/apercu-light.eot');
    src: url('../scss/fonts/apercu/apercu-light.eot?#iefix') format('embedded-opentype'),
         url('../scss/fonts/apercu/apercu-light.woff2') format('woff2'),
         url('../scss/fonts/apercu/apercu-light.woff') format('woff'),
         url('../scss/fonts/apercu/apercu-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('../scss/fonts/apercu/apercu-regular.eot');
    src: url('../scss/fonts/apercu/apercu-regular.eot?#iefix') format('embedded-opentype'),
         url('../scss/fonts/apercu/apercu-regular.woff2') format('woff2'),
         url('../scss/fonts/apercu/apercu-regular.woff') format('woff'),
         url('../scss/fonts/apercu/apercu-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('../scss/fonts/apercu/apercu-bold.eot');
    src: url('../scss/fonts/apercu/apercu-bold.eot?#iefix') format('embedded-opentype'),
         url('../scss/fonts/apercu/apercu-bold.woff2') format('woff2'),
         url('../scss/fonts/apercu/apercu-bold.woff') format('woff'),
         url('../scss/fonts/apercu/apercu-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

// Platin Light

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=8d921965-fb59-4e80-8735-5334141af6a3&fontids=5607912");

  @font-face {
      font-family:"Plantin Light";
      src:url("../scss/fonts/plantin/2c17b39d-2d4b-4aea-830c-187fabc5bc3d.eot?#iefix");
      src:url("../scss/fonts/plantin/2c17b39d-2d4b-4aea-830c-187fabc5bc3d.eot?#iefix") format("eot"),url("../scss/fonts/plantin/94834402-7ccb-47a0-a20f-28759066e23b.woff2") format("woff2"),url("../scss/fonts/plantin/50d3e854-4121-44aa-b019-bd0d8a758cb3.woff") format("woff"),url("../scss/fonts/plantin/c81ca7fe-bf4d-490f-b606-f277f6bf37cb.ttf") format("truetype");
  }


// Fallback fonts
//

// Crimson Text
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');

// Lato
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');
