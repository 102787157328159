// video.scss

.embed-responsive {
  background: #fafafa;
  border: 48px solid #fafafa;
}

// add box shadow to iframe containers
.player.player {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  margin-bottom: 5px;
}