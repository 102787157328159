// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

@import 'modules/colors';

// $primary:       $off-black;
// $primary-2:     $off-black;
// $primary-3:     $off-black;

$primary:       #ffffff;
$primary-2:     #ffffff;
$primary-3:     #ffffff;

$primary-escaped:        $off-black;
$carousel-caption-color: $off-black;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$primary-lighter: rgba(16,85,64, 0.5);

// Grid containers
 
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1024px
);

// Grid containers

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);

// Grid columns

$grid-gutter-width:           32px;

// Stylelint-disable

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0:  0,
  1:  8px,
  2:  16px,
  3:  24px,
  4:  32px,
  5:  40px,
  6:  48px,
  7:  96px,
  8:  120px,
  9:  144px,
  10: 176px,
), $spacers);

// Custom spacers

$spacing-bs: 8px; 
$spacing-sm: 24px; 
$spacing-md: 48px; 
$spacing-lg: 96px; 
$spacing-xl: 144px; 

// Typography
 
$sans-serif: "Noto Sans", sans-serif;
// $sans-serif: "Apercu", "Noto Sans", sans-serif;

$serif: "Noto Serif KR", Georgia, serif;

$font-family-sans-serif: $sans-serif;

$font-family-base:     $sans-serif;
$headings-font-family:   $serif;
$headings-font-weight:   normal;
$display1-weight:        normal;
$display2-weight:        normal;
$display3-weight:        normal;
$display4-weight:        normal;

$body-color: $off-black; 

html {
  font-size: 20px !important;
}

$font-size-base: 1rem;

$text-muted: #6c757d;

// Utilities

$border-radius-base: 3px;
$border-radius:      3px;
