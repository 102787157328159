// homepage

.homepage {

  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 40px;
    }
  }
  h3 {
    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }
  h5.text-muted {
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}

.homepage h1 a:hover {
  color: $headings-color;
}

.display-4 a:hover {
  color: inherit;
} 

.homepage section {
  padding: 3rem 0;
  @include media-breakpoint-up(md) {
    padding: 6rem 0;
  }
}