// carousel.scss
//

// Project carousel

.project-carousel {

  .card {
    border: none;
  }
  .card-body {
      padding: 1rem 0;

      h5 {
        margin-bottom: 0.25rem;
      }
  }
  .card-img-top {
      border-top-left-radius:  0;
      border-top-right-radius: 0;
  }
}

.owl-carousel h5 {
  display: inline;
}
.owl-carousel span {
  display: block;
  margin-top: 0.25rem;
}

.owl-carousel a:hover h5 {
  color: rgb(51,51,51);
    padding-bottom: 3px;
    background: linear-gradient(to right, rgb(51,51,51) 0%, rgb(51,51,51) 100%);
    background-size: 1px 1px;
    background-position: 0 100%;
    background-repeat: repeat-x;
}

// Flickity carousel

[data-flickity].controls-light .flickity-page-dots .dot {
    background: $off-black;
}

.flickity-prev-next-button {
    padding: 0;
    background: #fff;
    border: 1px solid #dee2e6;
    width: 3rem;
    height: 3rem;
    transition: opacity 0.2s linear, box-shadow 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
}