// typography.scss
//

// headers

h1 {
  font-size: 48px;
  &, & a {
    font-weight: 600;
  }
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 30px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  font-weight: 500;
}

h5 {
  font-size: 22px;
  font-weight: 600;
}

html {
  font-size: 1rem;
}

.section, .project-carousel {

  h2 {
    margin-bottom: 32px;
  }

  h3 {
    margin-top: 32px;
  }
}

.about h3 {
  margin-top: 0;
}

// body

body { 
  -webkit-font-smoothing: antialiased;
  font-size: 22px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

span {
  &.font-italic {
    color: $text-muted;
  }
}
figcaption {
  font-weight: normal;
}

.font-weight-medium {
  font-weight: 500;
}

.text-medium {
  font-size: 20px;
}