// tabs.scss

.nav-pills {

  a {
    // font-family: $sans-serif;
    font-weight: bolder;
  }
  
  .nav-link {
    
    & {
      color: $gray-100;
    }
  
    &:first-of-type {

      padding-left: 0;
      padding-right: 32px;

      &.active,
      &:hover,
      .show > .nav-link {
        color: $off-black;
        background-color: initial;
      }
    }
  }
}

.nav-pills-scroll {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

// bootstrap fade class not working

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}