// about.scss

.about {
  
  .text-muted.font-weight-light,
  h4 .text-muted {

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }
}
